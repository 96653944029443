import dynamic from 'next/dynamic';

import { useGlobalConfig } from '../../hooks/configContext';
import BusinessUnitLink from '../../BusinessUnitLink/BusinessUnitLink';

type NavbarProps = {
  children?: React.ReactNode;
  homePageUrl?: string;
};

const LanguageSwitcher = dynamic(
  () => import('otovoweb/src/apps/Navigation/LanguageSwitcher'),
  { ssr: false },
);

const Navbar = ({ children, homePageUrl }: NavbarProps) => {
  const { BU_CONFIG } = useGlobalConfig();

  if (BU_CONFIG.slug === 'santander-br') {
    return null;
  }

  const isMultiLocaleBusinessUnit = BU_CONFIG.supported_locales.length > 1;

  return (
    <nav
      // Don't change this ID - useElementHeightById relies on it
      id="otovoNavBar"
      className="z-40 grid w-full grid-cols-3 items-center shadow"
    >
      <div>{isMultiLocaleBusinessUnit && <LanguageSwitcher />}</div>
      <div className="pb-3 pt-4 text-center">
        <BusinessUnitLink homePageUrl={homePageUrl} />
      </div>
      <div className="flex items-center justify-end pr-3">{children}</div>
    </nav>
  );
};

export default Navbar;
