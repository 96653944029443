export function getStorefrontUrlWithPartners(
  slug: string,
  fallback: string,
  partner?: string,
) {
  if (slug === 'iberdrola-fr') {
    return 'https://www.iberdrola.fr/particuliers/smart-energies/smart-solaire';
  }
  if (slug === 'vivi-it') {
    return 'https://www.viviesco.it/';
  }
  if (partner && partner === 'fastweb') {
    return '#';
  }
  return fallback;
}
