import Link from 'next/link';
import { useGlobalConfig } from '../hooks/configContext';
import BusinessUnitLogo from '../BusinessUnitLogo/BusinessUnitLogo';
import { getStorefrontUrlWithPartners } from '../lib/storefrontUrls';

type BusinessUnitLinkProps = {
  homePageUrl: string;
};

const BusinessUnitLink = ({ homePageUrl }: BusinessUnitLinkProps) => {
  const { STOREFRONT_URL, BU_CONFIG } = useGlobalConfig();
  const affiliatePartnerData = BU_CONFIG.affiliate;

  return (
    <Link
      className="flex justify-center"
      href={
        homePageUrl ||
        getStorefrontUrlWithPartners(
          BU_CONFIG.slug,
          STOREFRONT_URL,
          affiliatePartnerData?.name,
        )
      }
    >
      <BusinessUnitLogo {...BU_CONFIG} />
    </Link>
  );
};

export default BusinessUnitLink;
