import { withRouter } from 'next/router';
import injectConfig from 'otovoweb/src/injectConfig';
import { Component } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { logSentryError } from '../../lib/sentry';
import { Otovo$ConfigType } from '../../types/otovoweb';
import { Otovo$RouterProps } from '../../types/routes';
import FullScreenErrorPage from '../FullScreenErrorPage/FullScreenErrorPage';
import OtovoEmailLink from '../OtovoEmailLink';
import RainbowLink from '../RainbowLink/RainbowLink';
import m from './messages';

type State = {
  error: boolean;
  errorPathName: string | null | undefined;
};
type Props = Otovo$ConfigType &
  Otovo$RouterProps & {
    children: React.ReactNode;
    name: string;
  };

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { router } = props;
    const { error, errorPathName } = state;

    if (error && router.pathname !== errorPathName) {
      // Reset error when user navigate to different route.
      return {
        error: false,
        errorPathname: undefined,
      };
    }

    return null;
  }

  constructor() {
    super(undefined);
    this.state = {
      error: false,
      errorPathName: undefined,
    };
  }

  componentDidCatch(error: Error, extraInfo: Record<string, any>) {
    const { router } = this.props;
    this.setState({
      error: true,
      errorPathName: router.pathname,
    });
    const { name } = this.props;
    logSentryError(error, {
      errorBoundary: name,
      ...extraInfo,
    });
  }

  render() {
    const { config, children } = this.props;
    const { error } = this.state;
    const phoneNumber = config.BU_CONFIG.contact_phone_number;

    if (error) {
      return (
        <FullScreenErrorPage>
          <T
            {...m.defaultErrorDescription}
            values={{
              phoneNumber: (
                <RainbowLink
                  color="rgba(255, 255, 255, 0.7)"
                  href={`tel:${phoneNumber || ''}`}
                >
                  {phoneNumber}
                </RainbowLink>
              ),
              email: <OtovoEmailLink color="white" />,
            }}
          />
        </FullScreenErrorPage>
      );
    }

    return children;
  }
}

export default withRouter(injectConfig(ErrorBoundary));
