import { Box } from '@otovo/rainbow';
import { Cloud$BusinessUnitConfig } from '../types/cloudApi';

function getLogoSize(buSlug: string) {
  switch (buSlug) {
    case 'energygo-es':
      return {
        width: '145px',
        height: 'auto',
      };
    case 'imaginaenergia-es':
      return {
        width: 'auto',
        maxHeight: '54px',
      };
    case 'lucera-es':
      return {
        width: 'auto',
        minWidth: '180px',
        maxHeight: '32px',
      };
    case 'masmovil-es':
      return {
        width: 'auto',
        minWidth: '180px',
        maxHeight: '32px',
      };
    default:
      return {
        width: 'auto',
        height: '6',
      };
  }
}

const BusinessUnitLogo = ({
  logo,
  slug,
  company_name: companyName,
  affiliate,
}: Cloud$BusinessUnitConfig) => {
  const size = getLogoSize(slug);
  return (
    <Box
      as="img"
      src={affiliate?.logo || logo}
      alt={`${companyName} logo`}
      {...size}
    />
  );
};

export default BusinessUnitLogo;
