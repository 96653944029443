import { useGlobalConfig } from '../hooks/configContext';
import RainbowLink from './RainbowLink/RainbowLink';

type Props = {
  email?: string;
  emailLabel?: React.ReactNode;
  color?: string;
};

const OtovoEmailLink = ({ email, emailLabel, color }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const sendTo = email || BU_CONFIG.contact_email;
  return (
    <RainbowLink whiteSpace="no-wrap" href={`mailto:${sendTo}`} color={color}>
      {emailLabel || sendTo}
    </RainbowLink>
  );
};

export default OtovoEmailLink;
