import { FormattedMessage as T } from 'react-intl';

import RainbowLink from '@otovo/shared/components/RainbowLink/RainbowLink';
import { openChat } from '@otovo/shared/lib/chatUtils';
import { Rainbow$Props } from '@otovo/shared/types/rainbow';

import m from './messages';

type Props = { children: React.ReactNode } & Rainbow$Props;

const OpenChat = ({ children, ...rest }: Props) => (
  <RainbowLink as="button" {...rest} onClick={() => openChat()}>
    {children || <T {...m.defaultChatLinkLabel} />}
  </RainbowLink>
);

OpenChat.defaultProps = {
  children: null,
};

export default OpenChat;
