import { Box, H1 } from '@otovo/rainbow';
import { Rainbow$Prop } from '../../types/rainbow';

type ErrorPageProps = {
  children: React.ReactNode;
};

const Layout = ({
  children,
  bg = 'transparent',
}: ErrorPageProps & {
  bg?: Rainbow$Prop;
}) => (
  <Box px={4} pt={{ s: 8, m: 10 }} bg={bg} flexGrow={1}>
    <Box
      display="grid"
      maxWidth={16}
      mx="auto"
      position="relative"
      height="100%"
    >
      {children}
    </Box>
  </Box>
);

const Heading = ({
  children,
  color = 'initial',
}: ErrorPageProps & {
  color?: Rainbow$Prop;
}) => (
  <H1 fontSize={{ s: 6, m: 8 }} color={color} mb={2}>
    {children}
  </H1>
);

const Illustration = ({ children }: ErrorPageProps) => (
  <Box
    display="inline"
    alignSelf="end"
    maxWidth={{ s: '75%', m: 13 }}
    width="100%"
    ml="auto"
    mb="_1"
  >
    {children}
  </Box>
);

const ErrorPage = {
  Layout,
  Heading,
  Illustration,
};

export default ErrorPage;
