import { BodyM, Box } from '@otovo/rainbow';
import OpenChat from 'otovoweb/src/components/Button/OpenChat';
import { FormattedMessage as T } from 'react-intl';
import ModernHouseWithSolarPanels from '../../illustrations/2022/ModernHouseWithSolarPanels';
import { isChatAvailable } from '../../lib/chatUtils';
import ErrorPage from '../ErrorPage/ErrorPage';
import OtovoEmailLink from '../OtovoEmailLink';

import m from './messages';

type Props = {
  children?: React.ReactNode;
};

const FullScreenErrorPage = ({ children }: Props) => {
  const defaultMessage = isChatAvailable() ? (
    <T
      values={{
        chat: <OpenChat color="white" />,
        email: <OtovoEmailLink color="white" />,
      }}
      {...m.errorDescription}
    />
  ) : (
    <T
      values={{
        email: <OtovoEmailLink color="white" />,
      }}
      {...m.errorDescriptionWhenChatIsDisabled}
    />
  );
  return (
    <ErrorPage.Layout bg="blue_90">
      <Box pr={{ s: 0, m: 10 }} mb="7">
        <ErrorPage.Heading color="white">
          <T {...m.somethingWentWrong} />
        </ErrorPage.Heading>

        <BodyM color="blue_20">{children || defaultMessage}</BodyM>
      </Box>
      <ErrorPage.Illustration>
        <ModernHouseWithSolarPanels variant="night" />
      </ErrorPage.Illustration>
    </ErrorPage.Layout>
  );
};
export default FullScreenErrorPage;
